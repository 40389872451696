.logo-container {
    padding: 0 5px;
    height: 64px;
    top: 0px;
}

.logo-image {
    height: 34px;
    width: 24px;
    margin-top: -6px
}

.logo-text {
    margin-left: 15px;
    font-size: 2.4em;
    font-family: "Lemon Jelly Personal Use", Courier;
    font-display: swap;
    color: #fff;
    margin-right: 8px;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    background-image: linear-gradient(45deg, #e79b25, #ffbf00);
}