.home-card {
    max-width: 900px;
    margin: auto;
    border: 1px solid #d5d5d5;
}

.how-this-works-button {
    position: absolute;
    top: 0;
    right: 0;
}

.top-divider {
    margin-top: 16px;
    border-top-color: #bbbbbb !important;
}