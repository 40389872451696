body {
	background-color: #e7e7e7;
}
/* 
* {
    animation-duration: 0s !important;
} */

.ant-btn-loading-icon {
    transition: none !important;
}